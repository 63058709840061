import PropTypes from 'prop-types';
import React from 'react';
import {
  useWindowSize,
} from 'react-use';
import useIsScrolling from '../../hooks/useIsScrolling';
import SearchGridHeader from '../ProductGridHeader/SearchGridHeader';
import StickyRefineBar from '../Refine/StickyRefineBar';
import SearchGrid from '../SearchGrid';
import style from './SearchResultsPage.module.scss';
import useFeatureFlags from './hooks/useFeatureFlags';

const SearchGridWrapper = ({
  editableSearchFlag = false,
  elasticDepartmentData = null,
  facets = [],
  gridIntersecting = false,
  handleCheckBoxChange = () => { },
  handleStoreFilter = () => { },
  isElasticSearchEnabled = false,
  leftRailMobileFlag = false,
  handleSortChange = () => { },
  onClearAllBtnClick = () => { },
  onClearFacetTag = () => { },
  onDepartmentChange = () => { },
  onPaginationButtonClick = () => { },
  onPriceChange = () => { },
  parameters = {},
  sortData = {},
  stats = {},
  storeDetails = {},
}) => {
  const { width } = useWindowSize();
  const isDesktop = width > 1025;
  const isScrollingUp = useIsScrolling(['up']);
  const showStickyRefineBar = gridIntersecting && !isDesktop && !editableSearchFlag;
  const isFacetSelected = parameters.facet?.length > 0;
  const isClearAllButtonEnabled = isFacetSelected
    || !!parameters.filter
    || !!parameters.lowPrice
    || !!parameters.highPrice;

  const {
    priceFilterFlag,
    departmentSuggestionsFlag,
  } = useFeatureFlags();
  const isPriceFilterEnabled = priceFilterFlag && !isElasticSearchEnabled;
  const showAdditionalControls = stats?.total !== 0 || !departmentSuggestionsFlag;

  return (
    <>
      {showStickyRefineBar && isScrollingUp
        ? (
          <StickyRefineBar
            brand={parameters.brand}
            facet={parameters.facet}
            facetData={facets}
            filter={parameters.filter}
            hasPriceFilterEnabled={isPriceFilterEnabled}
            highPrice={parameters.highPrice}
            isFacetSelected={isFacetSelected}
            leftRailMobileFlag={leftRailMobileFlag}
            lowPrice={parameters.lowPrice}
            onCheckBoxChange={handleCheckBoxChange}
            onClearAllBtnClick={onClearAllBtnClick}
            onPriceChange={onPriceChange}
            onSortChange={handleSortChange}
            resultsCount={stats.refinedTotal}
            searchTerm={parameters.searchTerm}
            selectedSort={parameters.sort
              || sortData?.defaultSortOption}
            sortData={sortData}
          />
        ) : null}
      <div className={style.gridHeaderPlaceholder}>
        {showAdditionalControls && (
          <SearchGridHeader
            brand={parameters.brand}
            departmentId={parameters.departmentId}
            editableSearchFlag={editableSearchFlag}
            elasticDepartmentData={elasticDepartmentData}
            facet={parameters.facet}
            facetData={facets}
            filter={parameters.filter}
            handleStoreFilter={handleStoreFilter}
            hasClearButtonEnabled={isClearAllButtonEnabled}
            hasPriceFilterEnabled={isPriceFilterEnabled}
            highPrice={parameters.highPrice}
            isClearAllButtonEnabled={isClearAllButtonEnabled}
            isFacetSelected={isFacetSelected}
            leftRailMobileFlag={leftRailMobileFlag}
            lowPrice={parameters.lowPrice}
            onCheckBoxChange={handleCheckBoxChange}
            onClearAllBtnClick={onClearAllBtnClick}
            onDepartmentChange={onDepartmentChange}
            onFacetTagClick={onClearFacetTag}
            onPriceChange={onPriceChange}
            onSortChange={handleSortChange}
            searchTerm={parameters.searchTerm}
            selectedSort={parameters.sort
              || sortData?.defaultSortOption || ''}
            sortData={sortData}
            stats={stats}
            storeDetails={storeDetails}
          />
        )}
      </div>
      <SearchGrid
        brand={parameters.brand}
        countryFulfillmentStore={parameters.countryFulfillmentStore}
        departmentId={parameters.departmentId}
        departmentSuggestionsFlag={departmentSuggestionsFlag}
        elasticDepartmentData={elasticDepartmentData}
        facet={parameters.facet}
        filter={parameters.filter}
        highPrice={parameters.highPrice}
        initialDepartmentId={parameters.initialDepartmentId}
        initialSearchTerm={parameters.initialSearchTerm}
        lowPrice={parameters.lowPrice}
        onDepartmentChange={onDepartmentChange}
        onPaginationButtonClick={onPaginationButtonClick}
        rows={parameters.rows}
        searchTerm={parameters.searchTerm}
        sort={parameters.sort
          || sortData?.defaultSortOption}
        start={parameters.start}
        storePreview={parameters.storePreview}
      />
    </>
  );
};

export default SearchGridWrapper;

SearchGridWrapper.propTypes = {
  editableSearchFlag: PropTypes.bool,
  elasticDepartmentData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    defaultCategoryIdBySite: PropTypes.string,
  })),
  facets: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  gridIntersecting: PropTypes.bool,
  handleCheckBoxChange: PropTypes.func,
  handleSortChange: PropTypes.func,
  handleStoreFilter: PropTypes.func,
  isElasticSearchEnabled: PropTypes.bool,
  leftRailMobileFlag: PropTypes.bool,
  onClearAllBtnClick: PropTypes.func,
  onClearFacetTag: PropTypes.func,
  onDepartmentChange: PropTypes.func,
  onPaginationButtonClick: PropTypes.func,
  onPriceChange: PropTypes.func,
  parameters: PropTypes.shape({
    brand: PropTypes.string,
    searchTerm: PropTypes.string,
    countryFulfillmentStore: PropTypes.string,
    departmentId: PropTypes.string,
    facet: PropTypes.arrayOf(PropTypes.string),
    filter: PropTypes.string,
    highPrice: PropTypes.string,
    lowPrice: PropTypes.string,
    rows: PropTypes.string,
    sort: PropTypes.string,
    start: PropTypes.string,
    storePreview: PropTypes.string,
    initialSearchTerm: PropTypes.string,
    initialDepartmentId: PropTypes.string,
  }),
  sortData: PropTypes.shape({
    defaultSortOption: PropTypes.string,
    sortOptions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    })),
  }),
  stats: PropTypes.shape({
    total: PropTypes.number,
    refinedTotal: PropTypes.number,
  }),
  storeDetails: PropTypes.shape({
    storeName: PropTypes.string,
    storeId: PropTypes.string,
  }),
};
