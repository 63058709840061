import { useQuery } from '@apollo/client';
import Container from 'anf-core-react/components/Container';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import BreakpointContext from '../../context/breakpoint';
import GridPagination from '../GridPagination/GridPagination';
import { ERROR_MESSAGE } from '../Messages/Messages';
import FullPageGrid from '../ProductGrid/FullPageGrid';
import SkeletonProductGrid from '../ProductGrid/SkeletonGrid';
import RecommendationSlider from '../RecommendationSlider/RecommendationSlider';
import recsContainerStyles from '../RecommendationSlider/recommendationSlider.module.scss';
import SearchErrorPage from '../SearchErrorPage';
import { CLICK_BEHAVIORS } from '../types/productCardClickBehaviorType';
import SEARCH_RESULTS_PRODUCT_DATA from './queries';

function SearchGrid({
  brand,
  countryFulfillmentStore = '',
  departmentId = '',
  departmentSuggestionsFlag = false,
  elasticDepartmentData = null,
  facet = [],
  filter = '',
  forShopSimilar = false,
  highPrice = '',
  initialDepartmentId = '',
  initialSearchTerm = '',
  lowPrice = '',
  onDepartmentChange = () => { },
  onPaginationButtonClick = () => { },
  rows = '42',
  searchTerm,
  sort = '',
  start = '0',
  storePreview = 'false',
}) {
  const { large: isDesktop } = useContext(BreakpointContext);

  const searchResultsData = useQuery(SEARCH_RESULTS_PRODUCT_DATA, {
    variables: {
      brand,
      countryFulfillmentStore,
      departmentId,
      facet,
      filter,
      highPrice,
      lowPrice,
      rows,
      searchTerm,
      sort,
      start,
      storePreview,
      initialSearchTerm,
      initialDepartmentId,
    },
    skip: !searchTerm,
  });

  const searchResults = searchResultsData.data?.searchResults;

  if (searchResultsData.loading) return <SkeletonProductGrid cardAmount={12} />;
  if (searchResultsData.error) return ERROR_MESSAGE;

  return (
    <div className="search-grid-wrapper">
      {searchResults?.products.length > 0
        ? (
          <>
            <FullPageGrid
              productCardClickBehavior={forShopSimilar && !isDesktop
                ? CLICK_BEHAVIORS.quickview : CLICK_BEHAVIORS.productPage}
              products={searchResults?.products}
            />
            <GridPagination
              currentPage={searchResults.pagination.currentPage}
              onButtonClick={onPaginationButtonClick}
              rows={rows}
              start={start}
              totalPages={searchResults.pagination.totalPages}
            />
            <Container
              key="recommendation-slider-search-results-page"
            >
              <RecommendationSlider
                placementId="search_page.m_rr1|search_page.rr1"
                styles={recsContainerStyles.searchPageRecsWrapper}
              />
            </Container>
          </>
        )
        : (
          <SearchErrorPage
            departmentId={departmentId}
            departmentSuggestionsFlag={departmentSuggestionsFlag}
            elasticDepartmentData={elasticDepartmentData}
            onDepartmentChange={onDepartmentChange}
            searchTerm={searchTerm}
          />
        )}
    </div>
  );
}

SearchGrid.propTypes = {
  brand: PropTypes.string.isRequired,
  countryFulfillmentStore: PropTypes.string,
  departmentId: PropTypes.string,
  departmentSuggestionsFlag: PropTypes.bool,
  elasticDepartmentData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    defaultCategoryIdBySite: PropTypes.string,
  })),
  facet: PropTypes.arrayOf(PropTypes.string),
  filter: PropTypes.string,
  forShopSimilar: PropTypes.bool,
  highPrice: PropTypes.string,
  initialDepartmentId: PropTypes.string,
  initialSearchTerm: PropTypes.string,
  lowPrice: PropTypes.string,
  onPaginationButtonClick: PropTypes.func.isRequired,
  rows: PropTypes.string,
  searchTerm: PropTypes.string.isRequired,
  sort: PropTypes.string,
  onDepartmentChange: PropTypes.func,
  start: PropTypes.string,
  storePreview: PropTypes.string,
};

export default SearchGrid;
