import PropTypes from 'prop-types';
import React, {
  useCallback,
  useReducer,
  useRef,
} from 'react';
import { useWindowSize } from 'react-use';
import SearchResultsContext from '../../context/SearchResultsContext';
import useCRSParameterListener from '../../hooks/useCRSParameterListener';
import useFilterTriggerAnalytics from '../../hooks/useFilterTriggerAnalytics';
import useIsScrolling from '../../hooks/useIsScrolling';
import useStoreData from '../../hooks/useStoreData';
import useTranslatedText from '../../hooks/useTranslatedText';
import useUrlSync from '../../hooks/useUrlSync';
import DomNodePortal from '../../tools/DomNodePortal';
import {
  ACTION,
  EDITABLE_SEARCH_SCROLL_OFFSET,
  HEADER_SCROLL_OFFSET,
  PRODUCTS_PER_PAGE,
} from '../../tools/constants';
import getFacetKeysForAnalytics from '../../tools/getFacetKeysForAnalytics';
import getFacetmap from '../../tools/getFacetmap';
import getPageType from '../../tools/getPageType';
import getPriceFilterLabel from '../../tools/getPriceFilterLabel';
import getSelectedFacets from '../../tools/getSelectedFacets';
import isServer from '../../tools/isServer';
import reducer from '../../tools/parametersChange';
import $window from '../../tools/window';
import BreakpointProvider from '../BreakpointProvider';
import DepartmentSelector from '../DepartmentSelector';
import DigitalDataProvider, {
  DD_DISABLE_SWATCH_HOVER,
  DD_HYPERLINK_DESC,
  DD_MODEL_IMAGERY_TEST,
} from '../DigitalDataProvider';
import EditableSearchHeader from '../EditableSearch/EditableSearchHeader';
import FacetsLeftRail from '../FacetsLeftRail';
import ProductGridScrollHandler from '../ProductGridScrollHandler';
import RecommendationSlider from '../RecommendationSlider/RecommendationSlider';
import recsContainerStyles from '../RecommendationSlider/recommendationSlider.module.scss';
import useStickyIntersection from '../Refine/hooks/useStickyIntersection';
import { usePopularSearches } from '../SearchBox/hooks/useQueries';
import SearchDefaultView from '../SearchDefaultView';
import SearchErrorPage from '../SearchErrorPage';
import StoreToggle from '../StoreToggle';
import SearchGridWrapper from './SearchGridWrapper';
import style from './SearchResultsPage.module.scss';
import useFeatureFlags from './hooks/useFeatureFlags';
import useSearchResponse from './hooks/useSearchResponse';

function SearchResultsPage({
  brand: intlBrand,
  countryFulfillmentStore: intlCountryFulfillmentStore = '',
  departmentId: intlDepartmentId = '',
  initialDepartmentId = '',
  facet: intlFacet = [],
  filter: intlFilter = '',
  highPrice: intlHighPrice = '',
  lowPrice: intlLowPrice = '',
  userInputedSearchTerm = '',
  searchTerm: intlSearchTerm = '',
  sort: intlSort = '',
  start: intlStart = '0',
  storePreview = 'false',
}) {
  //  STATE:
  const intlFacetArray = typeof intlFacet === 'string' ? [intlFacet] : intlFacet;
  const gridWrapper = useRef(null);
  const gridIntersecting = useStickyIntersection(gridWrapper);
  const storeDetails = useStoreData();
  const popularSearchesList = usePopularSearches();
  const { width } = useWindowSize();
  const isScrollingUp = useIsScrolling(['up']);
  const [parameters, dispatch] = useReducer(reducer, {
    brand: intlBrand,
    countryFulfillmentStore: intlCountryFulfillmentStore,
    currentURL: $window.location?.pathname,
    departmentId: intlDepartmentId, // Provided by CRS (SOLR API version)
    initialDepartmentId: initialDepartmentId || '', // Provided by the URL
    facet: intlFacetArray,
    filter: intlFilter,
    highPrice: intlHighPrice,
    lowPrice: intlLowPrice,
    rows: PRODUCTS_PER_PAGE, // Constant value set to 42 to improve performance
    searchTerm: intlSearchTerm,
    sort: intlSort,
    submitMethod: 'toaster',
    start: intlStart,
    initialSearchTerm: userInputedSearchTerm,
    storePreview,
    userInputedSearchTerm,
  });
  const suggestedSearchterm = useRef('');

  // feature flags
  const {
    editableSearchFlag,
    debounceDelay,
    priceFilterFlag,
    departmentSuggestionsFlag,
    facetsLeftRailFlag,
    leftRailMobileFlag,
    hasShopMyStoreEnabled,
  } = useFeatureFlags();

  //  Search response
  const {
    data, previousData, loading, error,
  } = useSearchResponse(parameters, suggestedSearchterm, userInputedSearchTerm);

  // derived state:
  const userInitiatedAsyncSearch = parameters.submitMethod === 'user-initiated';
  const isFacetSelected = parameters.facet?.length > 0;
  const isDesktop = width > 1025;

  const isClearAllButtonEnabled = isFacetSelected
    || !!parameters.filter
    || !!parameters.lowPrice
    || !!parameters.highPrice;

  const noMatchFoundFor = useTranslatedText('noMatchFoundFor', {
    replacements: userInputedSearchTerm,
    skip: (userInputedSearchTerm === intlSearchTerm) || !userInputedSearchTerm,
    fallback: `No match found for "${userInputedSearchTerm}".`,
  });

  const showCorrectedSearchText = parameters.userInputedSearchTerm
    && noMatchFoundFor
    && parameters.userInputedSearchTerm !== parameters.searchTerm;

  // URL SYNC
  useUrlSync(parameters, dispatch);

  //  EVENT HANDLERS
  const syncSearchTerm = () => {
    if (suggestedSearchterm.current) {
      dispatch({
        type: ACTION.SEARCHTERM,
        payload: { searchTerm: suggestedSearchterm.current },
      });
    }
  };

  const onClearFacetTag = (payload) => {
    if ((isDesktop && facetsLeftRailFlag) || (!isDesktop && leftRailMobileFlag)) {
      const facetMap = getSelectedFacets(parameters.facet);
      // Check if selected checkbox is in already selected facet filters
      if (facetMap.has(payload.facetKey)) {
        const checkedFacets = facetMap.get(payload.facetKey);
        const index = checkedFacets.indexOf(payload.facetValue);
        if (index !== -1) {
          if (checkedFacets.length === 1) {
            facetMap.delete(payload.facetKey);
          } else {
            checkedFacets.splice(index, 1);
          }
        }
      }

      syncSearchTerm();
      dispatch({
        type: ACTION.FACET_TOGGLE,
        payload: [...facetMap],
      });
    } else {
      const correspondingLeftRailFilterInput = document.querySelector(
        `input[name="${payload.facetKey}"][value="${payload.facetValue}"],
        input[name="${payload.facetValue}"][value="${payload.facetKey},${payload.facetValue}"]`,
      );
      if (correspondingLeftRailFilterInput && correspondingLeftRailFilterInput.checked) {
        correspondingLeftRailFilterInput.click();
      }
    }
  };

  const onClearAllBtnClick = () => {
    syncSearchTerm();
    dispatch({
      type: ACTION.CLEAR_ALL_PARAMETERS,
    });
  };

  const handleCheckBoxChange = (event) => {
    const facetMap = getFacetmap(event, parameters.facet);

    syncSearchTerm();
    dispatch({
      type: ACTION.FACET_TOGGLE,
      payload: [...facetMap],
    });

    if ($window.digitalData) {
      const analyticsEvent = {
        search_filter_applied_category: `${[...getFacetKeysForAnalytics(facetMap)]}`,
        search_filter_applied_value_name: `${[...facetMap.values()]}`,
        event_name: 'filter_applied',
        event_type: 'filters',
        page_description: (!parameters.departmentId) ? 'All Genders' : parameters.departmentId,
        tealium_event: 'filter_applied',
      };
      $window.digitalData.trigger('search_filter_applied', analyticsEvent);
    }
  };

  const handleStoreFilter = useCallback((storeId) => {
    syncSearchTerm();
    dispatch({
      type: ACTION.LOCAL_STORE_TOGGLE,
      payload: storeId,
    });
  }, [dispatch]);

  // Need this for backward compatibility
  const handleCRSParameterChange = useCallback((event) => {
    syncSearchTerm();
    dispatch({
      type: ACTION.CRS_UPDATE,
      payload: event.detail,
    });
  }, [dispatch]);

  const handleSortChange = (event) => {
    syncSearchTerm();
    dispatch({
      type: ACTION.SORT_UPDATE,
      payload: event.target.value,
    });
    $window.digitalData.trigger(`${getPageType()}_sort_applied`, {
      event_name: 'sort_applied',
      event_type: 'sort',
      search_filter_applied_value_name: event.target.value,
      tealium_event: 'sort_applied',
    });
  };

  const onPaginationButtonClick = (event, start) => {
    syncSearchTerm();
    dispatch({
      type: ACTION.PAGINATION,
      payload: start,
    });
  };

  const onPriceChange = (obj) => {
    if ($window.digitalData) {
      const analyticsEvent = {
        search_price_filter_applied_range: getPriceFilterLabel(obj.lowPrice, obj.highPrice),
        search_min_price_filter_applied_value: obj.lowPrice,
        search_max_price_filter_applied_value: obj.highPrice,
        search_filter_applied_category: 'Price',
        search_filter_applied_value_name: getPriceFilterLabel(obj.lowPrice, obj.highPrice),
        event_name: 'filter_applied',
        event_type: 'filters',
        page_description: (!parameters.departmentId) ? 'All Genders' : parameters.departmentId,
      };
      $window.digitalData.trigger('search_filter_applied', analyticsEvent);
    }
    syncSearchTerm();
    dispatch({
      type: ACTION.PRICE,
      payload: {
        highPrice: obj.highPrice,
        lowPrice: obj.lowPrice,
      },
    });
  };

  const onDepartmentChange = (event) => {
    syncSearchTerm();
    dispatch({
      type: ACTION.DEPARTMENT_CHANGE,
      payload: event.target,
    });
  };

  /**
   * Submit search term from Editable Search Header or Search Default View
   * @param {Object} submission - Object containing the search term and submit method
   * @param {string} submission.value - Search term
   * @param {string} submission.submitMethod - Method used to submit the search term,
   * which will be undefined from Search Default View
   * @returns {void}
   */
  const onSearchTermChange = (submission) => {
    const searchTerm = submission?.value;
    const submitMethod = submission?.submitMethod || 'user-initiated';
    dispatch({
      type: ACTION.SEARCHTERM,
      payload: { searchTerm, submitMethod },
    });
  };

  const handleCategoryParametersChanged = useCallback((event) => {
    // if facets are on the only possible event we can get from CRS would be a store filter change
    if (facetsLeftRailFlag) {
      const storeId = event.detail.filter?.split(':')[1] ?? '';
      handleStoreFilter(storeId);
    } else {
      handleCRSParameterChange(event);
    }
  }, [facetsLeftRailFlag, handleCRSParameterChange, handleStoreFilter]);

  //  SIDE EFFECTS

  useFilterTriggerAnalytics(storeDetails, parameters.filter);
  // Need this for backward compatibility
  useCRSParameterListener(handleCategoryParametersChanged);

  if (error) {
    console.error('SearchResultsPage : getSearchData : Error executing GraphQL query', {
      searchTermParameter: intlSearchTerm,
      facetParameter: parameters.facet,
      departmentIdParameter: parameters.departmentId,
      error,
    });

    if (isServer()) {
      return null;
    }

    return <SearchErrorPage error={error} />;
  }

  let currentData;

  if (loading) {
    if (!previousData) {
      return null;
    }
    currentData = previousData;
  } else {
    currentData = data;
  }

  const {
    departmentId,
    facets,
    isElasticSearchEnabled,
    sortData,
    stats,
    departments: elasticDepartmentData,
    searchSuggestions,
  } = currentData?.searchResults || {};

  const isPriceFilterEnabled = priceFilterFlag && !isElasticSearchEnabled;
  const showAdditionalControls = stats?.total !== 0 || !departmentSuggestionsFlag;

  if ($window.digitalData) {
    $window.digitalData.merge('search', {
      isElasticSearchEnabled,
    });
  }
  const suggestionObject = searchSuggestions?.byName;

  //  RENDER
  const departmentDropdown = (
    <DomNodePortal targetNodeSelector=".mfe-department-selector-container">
      {isDesktop && parameters.searchTerm && showAdditionalControls
        ? (
          <DepartmentSelector
            departmentId={(departmentId !== undefined && departmentId !== null
              ? departmentId : parameters.departmentId || initialDepartmentId)}
            elasticDepartmentData={elasticDepartmentData}
            fromLeftRail
            onDepartmentChange={onDepartmentChange}
            searchTerm={intlSearchTerm}
          />
        ) : null}
      {showCorrectedSearchText ? (
        <span className={style.correctedTerm} data-testid="correctedSearchTerm">{noMatchFoundFor.value}</span>
      ) : null}
    </DomNodePortal>
  );
  const leftRailFacets = isDesktop && facetsLeftRailFlag ? (
    <DomNodePortal targetNodeSelector=".sort-and-filter-facet-container">
      {hasShopMyStoreEnabled && showAdditionalControls && (
        <div className={style.storeToggleWrapper}>
          <StoreToggle
            handleStoreFilter={handleStoreFilter}
            isShopMyStore={parameters.filter !== ''}
            storeDetails={storeDetails}
          />
          <hr />
        </div>
      )}
      {facets?.length > 0 && parameters.searchTerm && (
        <FacetsLeftRail
          brand={parameters.brand}
          facet={parameters.facet}
          facetData={facets}
          hasClearButtonEnabled={isClearAllButtonEnabled}
          hasPriceFilterEnabled={isPriceFilterEnabled}
          highPrice={parameters.highPrice}
          lowPrice={parameters.lowPrice}
          onCheckBoxChange={handleCheckBoxChange}
          onClearAllBtnClick={onClearAllBtnClick}
          onPriceChange={onPriceChange}
        />
      )}
    </DomNodePortal>
  ) : null;

  const editableSearchHeader = (
    <DomNodePortal targetNodeSelector=".editable-search-container">
      <EditableSearchHeader
        brand={parameters.brand}
        debounceDelay={debounceDelay}
        facet={parameters.facet}
        facetData={currentData?.searchResults?.facets}
        filter={parameters.filter}
        hasPriceFilterEnabled={isPriceFilterEnabled}
        highPrice={parameters.highPrice}
        isDesktop={isDesktop}
        isFacetSelected={isFacetSelected}
        leftRailMobileFlag={leftRailMobileFlag}
        lowPrice={parameters.lowPrice}
        onCheckBoxChange={handleCheckBoxChange}
        onClearAllBtnClick={onClearAllBtnClick}
        onPriceChange={onPriceChange}
        onSearchTermChange={onSearchTermChange}
        onSortChange={handleSortChange}
        resultsCount={currentData?.searchResults?.stats.refinedTotal}
        searchTerm={parameters.searchTerm}
        selectedSort={parameters.sort
          || currentData?.searchResults?.sortData?.defaultSortOption}
        showStickyBar={gridIntersecting && isScrollingUp}
        sortData={currentData?.searchResults?.sortData}
        suggestionObject={suggestionObject}
      />
    </DomNodePortal>
  );

  return (
    <BreakpointProvider>
      <DigitalDataProvider
        keys={[
          DD_DISABLE_SWATCH_HOVER,
          DD_HYPERLINK_DESC,
          DD_MODEL_IMAGERY_TEST,
        ]}
      >
        <SearchResultsContext.Provider value={{ userInitiatedAsyncSearch }}>
          <ProductGridScrollHandler
            parameters={parameters}
            scrollOffset={editableSearchFlag ? EDITABLE_SEARCH_SCROLL_OFFSET : HEADER_SCROLL_OFFSET}
          >
            {editableSearchFlag ? editableSearchHeader : null}
            {departmentDropdown}
            {leftRailFacets}
            {editableSearchFlag && parameters.searchTerm === ''
              ? (
                <SearchDefaultView
                  listItemSubmitHandler={onSearchTermChange}
                  popularSearchesList={popularSearchesList}
                  wrapperId="editable-search-list"
                >
                  <RecommendationSlider
                    placementId="search_page.m_rr1|search_page.rr1"
                    styles={recsContainerStyles.searchPageRecsWrapper}
                  />
                </SearchDefaultView>
              )
              : (
                <div ref={gridWrapper}>
                  <SearchGridWrapper
                    editableSearchFlag={editableSearchFlag}
                    elasticDepartmentData={elasticDepartmentData}
                    facets={facets}
                    gridIntersecting={gridIntersecting}
                    handleCheckBoxChange={handleCheckBoxChange}
                    handleSortChange={handleSortChange}
                    handleStoreFilter={handleStoreFilter}
                    isElasticSearchEnabled={isElasticSearchEnabled}
                    leftRailMobileFlag={leftRailMobileFlag}
                    onClearAllBtnClick={onClearAllBtnClick}
                    onClearFacetTag={onClearFacetTag}
                    onDepartmentChange={onDepartmentChange}
                    onPaginationButtonClick={onPaginationButtonClick}
                    onPriceChange={onPriceChange}
                    parameters={parameters}
                    sortData={sortData}
                    stats={stats}
                    storeDetails={storeDetails}
                  />
                </div>
              )}
          </ProductGridScrollHandler>
        </SearchResultsContext.Provider>
      </DigitalDataProvider>
    </BreakpointProvider>
  );
}

SearchResultsPage.propTypes = {
  // Required props
  brand: PropTypes.string.isRequired,
  // Optional props
  countryFulfillmentStore: PropTypes.string,
  departmentId: PropTypes.string,
  initialDepartmentId: PropTypes.string,
  facet: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  filter: PropTypes.string,
  highPrice: PropTypes.string,
  lowPrice: PropTypes.string,
  userInputedSearchTerm: PropTypes.string,
  searchTerm: PropTypes.string,
  sort: PropTypes.string,
  start: PropTypes.string,
  storePreview: PropTypes.string,
};

export default SearchResultsPage;
