import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import BreakpointContext from '../../context/breakpoint';
import DepartmentSelector from '../DepartmentSelector';
import RefineButton from '../Refine';
import SortBy from '../SortBy/SortBy';
import BaseSearchHeader from './BaseSearchHeader';
import style from './ProductGridHeader.module.scss';

function SearchGridHeader({
  brand,
  departmentId = '',
  editableSearchFlag = false,
  elasticDepartmentData = null,
  facet = [],
  facetData = [],
  filter = '',
  handleStoreFilter = () => {},
  highPrice = '',
  hasPriceFilterEnabled = false,
  isClearAllButtonEnabled = false,
  leftRailMobileFlag = false,
  lowPrice = '',
  onClearAllBtnClick = () => {},
  onDepartmentChange = () => {},
  onCheckBoxChange = () => {},
  onFacetTagClick = () => {},
  onPriceChange = () => {},
  onSortChange = () => {},
  searchTerm = '',
  selectedSort = '',
  sortData = {
    sortOptions: [],
  },
  stats = {
    total: 0,
    refinedTotal: 0,
  },
  storeDetails = { storeName: '', storeId: '' },
}) {
  const breakpoints = useContext(BreakpointContext);
  const { large: isDesktop } = breakpoints;
  const baseSearchHeader = (
    <BaseSearchHeader
      count={stats?.refinedTotal || 0}
      editableSearchFlag={editableSearchFlag}
      facet={facet}
      filter={filter}
      handleStoreFilter={handleStoreFilter}
      highPrice={highPrice}
      isClearAllButtonEnabled={isClearAllButtonEnabled}
      lowPrice={lowPrice}
      onClearAllBtnClick={onClearAllBtnClick}
      onFacetTagClick={onFacetTagClick}
      onPriceChange={onPriceChange}
      searchTerm={searchTerm}
      storeDetails={storeDetails}
    />
  );

  if (!isDesktop) {
    return (
      <div className={`${style.productGridHeader} ${style.productGridHeaderMobile}`}>
        {baseSearchHeader}
        <DepartmentSelector
          departmentId={departmentId}
          elasticDepartmentData={elasticDepartmentData}
          onDepartmentChange={onDepartmentChange}
          searchTerm={searchTerm}
        />
        <div className={style.lastColumn}>
          <RefineButton
            brand={brand}
            facet={facet}
            facetData={facetData}
            filter={filter}
            handleStoreFilter={handleStoreFilter}
            hasPriceFilterEnabled={hasPriceFilterEnabled}
            highPrice={highPrice}
            isCategoryPage={false}
            isFacetSelected={facet?.length > 0 || !!filter}
            leftRailMobileFlag={leftRailMobileFlag}
            lowPrice={lowPrice}
            onCheckBoxChange={onCheckBoxChange}
            onClearAllBtnClick={onClearAllBtnClick}
            onPriceChange={onPriceChange}
            onSortChange={onSortChange}
            resultsCount={stats?.refinedTotal}
            selectedSort={selectedSort}
            sortData={sortData}
            storeDetails={storeDetails}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={`${style.productGridHeader} ${style.productGridHeaderDesktop}`}>
      {baseSearchHeader}
      <div className={style.lastColumn}>
        {sortData.sortOptions?.length !== 0
          ? (
            <SortBy
              onSortChange={onSortChange}
              selectedSort={selectedSort}
              sortData={sortData}
            />
          )
          : null}
      </div>
    </div>
  );
}

SearchGridHeader.propTypes = {
  brand: PropTypes.string.isRequired,
  departmentId: PropTypes.string,
  editableSearchFlag: PropTypes.bool,
  elasticDepartmentData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    defaultCategoryIdBySite: PropTypes.string,
  })),
  facet: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  facetData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  filter: PropTypes.string,
  handleStoreFilter: PropTypes.func,
  hasPriceFilterEnabled: PropTypes.bool,
  highPrice: PropTypes.string,
  isClearAllButtonEnabled: PropTypes.bool,
  leftRailMobileFlag: PropTypes.bool,
  lowPrice: PropTypes.string,
  onCheckBoxChange: PropTypes.func,
  onClearAllBtnClick: PropTypes.func,
  onDepartmentChange: PropTypes.func,
  onFacetTagClick: PropTypes.func,
  onPriceChange: PropTypes.func,
  onSortChange: PropTypes.func,
  searchTerm: PropTypes.string,
  selectedSort: PropTypes.string,
  sortData: PropTypes.shape({
    sortOptions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    })),
  }),
  stats: PropTypes.shape({
    total: PropTypes.number,
    refinedTotal: PropTypes.number,
  }),
  storeDetails: PropTypes.shape({
    storeName: PropTypes.string,
    storeId: PropTypes.string,
  }),
};

export default SearchGridHeader;
